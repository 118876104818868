import { useSearchParams } from 'react-router-dom';

export interface Token {
  henne: boolean;
  chabbat: boolean;
}

export const useToken = (): Token => {
  const [searchParams] = useSearchParams();

  let token: Token = {
    henne: false,
    chabbat: false,
  };
  try {
    token = JSON.parse(atob(searchParams.get('token') ?? ''));
    // eslint-disable-next-line no-empty
  } catch {}

  return token;
};
