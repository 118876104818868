import { FaCalendar, FaMapPin, FaSchool, FaStarOfDavid } from 'react-icons/fa';
import React, { useEffect } from 'react';

import { GiBigDiamondRing } from 'react-icons/gi';
import { GoEye } from 'react-icons/go';
import { useInView } from 'react-intersection-observer';
import { useToken } from '../../../../hooks';

const Planning: React.FC = () => {
  const token = useToken();
  const { ref, inView } = useInView();

  const [animated, setAnimated] = React.useState(false);

  useEffect(() => {
    if (inView && !animated) {
      setAnimated(true);
    }
  }, [animated, inView]);

  const events = [
    ...[
      {
        name: 'La Mairie',
        date: 'Mardi 20 Décembre 2022 - 13H30',
        place: 'Mairie du XIXème',
        details: (
          <>
            <p>
              <span className="font-medium">Adresse :</span> 5-7 Pl. Armand Carrel, 75019 Paris
            </p>
          </>
        ),
        icon: <FaSchool className="fill-red-300 text-xl" />,
      },
    ],
    ...(token.henne
      ? [
          {
            name: 'Le Henné',
            date: 'Mardi 20 Décembre 2022 - 19H30',
            place: 'Espace Royal',
            details: (
              <>
                <p>
                  <span className="font-medium">Adresse :</span> 6 Rue de Valmy, 93100 Montreuil
                </p>
                <p>
                  <span className="font-medium">Parking :</span> disponible sur place
                </p>
              </>
            ),
            icon: <GoEye className="fill-red-300 text-2xl" />,
          },
        ]
      : []),
    ...[
      {
        name: 'La Houppa & La Soirée',
        date: 'Jeudi 22 Décembre 2022 - 17H30',
        place: 'La Palmeraie',
        details: (
          <>
            <p>
              <span className="font-medium">Adresse :</span> 20 Rue du Colonel Pierre Avia, 75015
              Paris
            </p>
            <p>
              <span className="font-medium">Parking :</span> Indigo Aquaboulebard (payant)
            </p>
          </>
        ),
        icon: <GiBigDiamondRing className="fill-red-300 text-2xl" />,
      },
    ],
    ...(token.chabbat
      ? [
          {
            name: 'Le Chabbat',
            date: 'Vendredi 23 & Samedi 24 Décembre 2022',
            place: 'Synagogue Michkenot Israël',
            details: (
              <>
                <p>
                  <span className="font-medium">Adresse :</span> 6 Rue Jean Nohain, 75019 Paris
                </p>
                <p className="font-medium">Hotels à proximité :</p>
                <ul className="list-disc ml-6">
                  <li>Ibis Styles Paris Buttes-Chaumont (hôtel des mariés)</li>
                  <li>Ibis Budget Paris La Villette</li>
                </ul>
              </>
            ),
            icon: <FaStarOfDavid className="fill-red-300 text-2xl" />,
          },
        ]
      : []),
  ];

  return (
    <div id="planning" className="w-full p-8 bg-slate-100">
      <div ref={ref} className={animated ? 'animate-[fromRight_1s_ease-out]' : ''}>
        <div className="font-['Great_Vibes'] tracking-[0.1em] text-slate-900 w-full text-center text-4xl py-8 font-light">
          Planning
        </div>
        {events.map((event, idx) => (
          <div
            key={event.name}
            className={`relative w-full border-l-2 pl-10 pr-4 pb-8 ml-4 sm:ml-12 font-light ${
              idx === events.length - 1 ? 'border-slate-100' : 'border-red-300'
            }`}
          >
            <div className="absolute top-0 -left-[1.625rem] bg-slate-100 border-red-300 border-2 rounded-full flex items-center justify-center w-12 h-12">
              {event.icon}
            </div>
            <div className="text-2xl min-h-[3rem] flex items-center text-red-400 font-extralight">
              {event.name}
            </div>
            <div className="flex items-center gap-2 text-slate-900">
              <FaCalendar className="fill-slate-800" /> {event.date}
            </div>
            <div className="flex items-center gap-2 text-slate-900">
              <FaMapPin className="fill-slate-800" /> {event.place}
            </div>
            <div className="pt-4 text-slate-500">
              <div className="font-medium">Détails</div>
              <div className="pl-3 ml-1 border-l-2 border-slate-500">{event.details}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Planning;
