import EventTemplate from './EventTemplate';
import React from 'react';

const Mairie: React.FC = () => (
  <EventTemplate
    bgImage="/img/mairie.png"
    title="La Mairie"
    content={
      <>
        <div className="font-['Great_Vibes'] tracking-[0.2em] w-full flex items-center justify-center text-center text-4xl">
          Léa & David
        </div>
        <div className="w-full text-center italic text-xl my-10">se diront</div>
        <div className="w-full flex items-center justify-center text-center text-5xl font-thin text-red-400 drop-shadow">
          <span className="tracking-[0.4em]">&laquo;oui</span>&raquo;
        </div>
        <div className="w-full text-center mt-8 font-light text-md">
          <p>le Mardi 20 Décembre 2022</p>
          <p>à 13H30</p>
          <p>à la mairie du XIXème arrondissement de Paris</p>
          <p className="italic text-sm">5-7 Pl. Armand Carrel, 75019 Paris</p>
        </div>
      </>
    }
  />
);

export default Mairie;
