import React, { useRef } from 'react';

import Invitation from './views/Invitation';

// import RSVPForm from './views/RSVPForm';

const App: React.FC = () => {
  // const [displayForm, setDisplayForm] = React.useState<boolean>(false);
  // const [displayConfirm, setDisplayConfirm] = React.useState<boolean>(false);
  const leftRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const f = () => setDisplayConfirm((leftRef.current?.scrollTop || 0) >= 80);

  //   leftRef.current?.addEventListener('scroll', f);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   return () => leftRef.current?.removeEventListener('scroll', f);
  // }, []);

  return (
    <>
      <div className="w-full h-screen flex flex-row relative overflow-x-hidden">
        <div
          ref={leftRef}
          // ${displayForm ? '-left-full' : 'left-0'}
          className={`
            left-0
            w-full h-full overflow-y-scroll overflow-x-hidden
            absolute inset-y-0 transition-left duration-500 pb-[76px]
          `}
        >
          <Invitation
            scrollTo={(top: number) => leftRef.current?.scrollTo({ top, behavior: 'smooth' })}
          />
        </div>
        {/* <div
          className={`
            ${displayForm ? 'left-0' : 'left-full'} 
            w-full h-full overflow-y-scroll overflow-x-hidden
            absolute inset-y-0 transition-left duration-500 pb-[76px]
          `}
        >
          <RSVPForm />
        </div> */}
      </div>
      {/* {(displayConfirm || displayForm) && (
        <div className="fixed bottom-0 w-full backdrop-blur-lg px-12 py-3 z-20">
          <div
            onClick={() => setDisplayForm(!displayForm)}
            className="cursor-pointer p-3 rounded-md text-xl font-semibold text-center bg-red-300 text-slate-100"
          >
            {displayForm ? 'Retour' : 'Confirmer ma présence'}
          </div>
        </div>
      )} */}
    </>
  );
};

export default App;
