import React, { useEffect } from 'react';

import { FaMapMarkedAlt } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

interface MapsProps {
  title: string;
  src: string;
}

const Maps: React.FC<MapsProps> = ({ title, src }) => {
  const { ref, inView } = useInView();

  const [animated, setAnimated] = React.useState(false);

  useEffect(() => {
    if (inView && !animated) {
      setAnimated(true);
    }
  }, [animated, inView]);

  return (
    <div className="w-full p-8 bg-slate-100 text-slate-900">
      <div ref={ref} className={animated ? 'animate-[fromRight_1s_ease-out]' : ''}>
        <div className="w-full flex gap-3 items-center justify-center text-center text-3xl sm:text-4xl py-8 font-extralight">
          <FaMapMarkedAlt className="fill-slate-900" /> {title}
        </div>
        <div className="py-8 drop-shadow-lg">
          <iframe src={src} width="100%" height="450" referrerPolicy="no-referrer-when-downgrade" />
        </div>
      </div>
    </div>
  );
};

export default Maps;
