import EventTemplate from './EventTemplate';
import { FaMenorah } from 'react-icons/fa';
import React from 'react';

const Mariage: React.FC = () => (
  <EventTemplate
    bgImage="/img/houppa.png"
    title="La Houppa & Soirée"
    content={
      <>
        <div className="w-full text-center text-2xl italic">
          קוֹל שָשוֹן וְקוֹל שִמְחָה קוֹל חָתָן וְקוֹל כָּלָה
        </div>
        <div className="my-10 grid grid-rows-2 md:grid-cols-2 md:grid-rows-1 gap-16 place-content-around">
          <div className="flex flex-col items-center justify-content">
            <img src="/img/mariee.png" alt="mariee" className="w-20 drop-shadow" />
            <div className="font-['Great_Vibes'] tracking-[0.1em] text-red-400 text-2xl text-center">
              Famille de la mariée
            </div>
            <div className="text-center italic">Mr & Mme Jacques Kohn</div>
            <div className="text-center italic">Mr & Mme Michel Abitbol</div>
          </div>
          <div className="flex flex-col items-center justify-content">
            <img src="/img/marie.png" alt="marie" className="w-20 drop-shadow" />
            <div className="font-['Great_Vibes'] tracking-[0.1em] text-red-400 text-2xl">
              Famille du marié
            </div>
            <div className="text-center italic">Mr & Mme Isaac Elmaleh</div>
          </div>
        </div>
        <div className="w-full text-center italic font-light my-10">
          ont l&apos;honneur et la joie de vous faire part du mariage de leurs petits enfants et
          enfants
        </div>
        <div className="w-full flex flex-col items-center justify-center text-center text-4xl my-10 gap-4 text-red-400">
          <p className="font-['Great_Vibes'] tracking-[0.1em]">Léa Dona Emilie</p>
          <p className="font-['Great_Vibes'] tracking-[0.1em]">&</p>
          <p className="font-['Great_Vibes'] tracking-[0.1em]">David Amram</p>
        </div>
        <div className="w-full text-center my-10 italic font-light">
          et vous prient de les honorer de votre présence à la cérémonie religieuse, suivie de la
          réception qui aura lieu
        </div>
        <div className="w-full text-center my-10 italic font-light">
          <p>le Jeudi 22 Décembre 2022</p>
          <p>à 17H30</p>
          <p>à la salle &apos;&apos;La Palmeraie&apos;&apos;</p>
          <p className="italic text-sm">20 Rue du Colonel Pierre Avia, 75015 Paris</p>
        </div>
        <div className="w-full text-center my-10 italic font-light">
          <p className="mb-4">
            En ce jour solennel, nous avons une pensée particulière pour nos chers grands-parents et
            cousin :
          </p>
          <p>Isaac & Denise Abitbol</p>
          <p>Haim & Esther Elmaleh</p>
          <p>Salomon & Soulika Gozlan</p>
          <p>Yoram Haim Yossef Serraf</p>
        </div>
        <div className="w-full flex items-center justify-center gap-2 text-center italic text-sm">
          <FaMenorah className="text-md" /> L&apos;allumage de la 5ème bougie de Hanouka est prévu
          sur place <FaMenorah className="text-md" />
        </div>
      </>
    }
  />
);

export default Mariage;
