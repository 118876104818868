import React, { useEffect } from 'react';

const Goodbye: React.FC = () => {
  const target = new Date(1671726600000);
  const [now, setNow] = React.useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const totalSeconds = (target.getTime() - now.getTime()) / 1000;
  const nbDays = Math.floor(totalSeconds / (60 * 60 * 24));
  const nbHours = Math.floor((totalSeconds - nbDays * 60 * 60 * 24) / (60 * 60));
  const nbMinutes = Math.floor((totalSeconds - (nbDays * 60 * 60 * 24 + nbHours * 60 * 60)) / 60);
  const nbSeconds = Math.floor(
    totalSeconds - (nbDays * 60 * 60 * 24 + nbHours * 60 * 60 + nbMinutes * 60),
  );

  return (
    <div className="w-full bg-slate-900">
      <div className="w-full backdrop-blur-sm p-8 text-center font-light flex flex-col gap-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
          <div className="text-center text-red-400">
            <div className="font-semibold text-4xl">{nbDays}</div>
            <div className="uppercase">jours</div>
          </div>
          <div className="text-center text-red-400">
            <div className="font-semibold text-4xl">{nbHours}</div>
            <div className="uppercase">heures</div>
          </div>
          <div className="text-center text-red-400">
            <div className="font-semibold text-4xl">{nbMinutes}</div>
            <div className="uppercase">minutes</div>
          </div>
          <div className="text-center text-red-400">
            <div className="font-semibold text-4xl">{nbSeconds}</div>
            <div className="uppercase">secondes</div>
          </div>
        </div>
        <div className="text-sm text-slate-100">
          Veuillez à présent confirmer votre présence en cliquant sur le bouton ci-dessous.
        </div>
        <div className="text-sm text-slate-100">Nous comptons sur votre présence !</div>
      </div>
    </div>
  );
};

export default Goodbye;
