import React, { useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

interface EventTemplateProps {
  bgImage: string;
  title: string;
  content: React.ReactNode;
}

const EventTemplate: React.FC<EventTemplateProps> = ({ bgImage, title, content }) => {
  const { ref, inView } = useInView();

  const [animated, setAnimated] = React.useState(false);

  useEffect(() => {
    if (inView && !animated) {
      setAnimated(true);
    }
  }, [animated, inView]);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, #f1f5f988, #f1f5f988), url('${bgImage}')`,
      }}
      className="w-full bg-cover md:bg-fixed bg-center"
    >
      <div ref={ref} className={animated ? 'animate-[fromLeft_1s_ease-out]' : ''}>
        <div className="w-full p-8">
          <div className="font-['Great_Vibes'] tracking-[0.1em] text-slate-900 w-full text-center text-4xl py-8 font-light">
            {title}
          </div>
          <div className="py-8 px-4 my-4 backdrop-blur-sm bg-slate-100/50 text-slate-900 rounded">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTemplate;
