import EventTemplate from './EventTemplate';
import { FaMenorah } from 'react-icons/fa';
import React from 'react';

const Henne: React.FC = () => (
  <EventTemplate
    bgImage="/img/henne.png"
    title="Le Henné"
    content={
      <>
        <div className="font-['Great_Vibes'] tracking-[0.2em] w-full flex items-center justify-center text-center text-4xl">
          Nathalie & Maggy
        </div>
        <div className="w-full text-center italic my-10">
          sont heureuses de vous convier à la soirée du henné qui aura lieu
        </div>
        <div className="w-full text-center my-10 font-light text-md">
          <p>le Mardi 20 Décembre 2022</p>
          <p>à 19H30</p>
          <p>à la salle &apos;&apos;Espace Royal&apos;&apos;</p>
          <p className="italic text-sm">6 Rue de Valmy, 93100 Montreuil</p>
        </div>
        <div className="w-full flex items-center justify-center gap-2 text-center italic text-sm">
          <FaMenorah className="text-md" /> L&apos;allumage de la 3ème bougie de Hanouka est prévu
          sur place <FaMenorah className="text-md" />
        </div>
      </>
    }
  />
);

export default Henne;
