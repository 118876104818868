import EventTemplate from './EventTemplate';
import React from 'react';

const Chabbat: React.FC = () => (
  <EventTemplate
    bgImage="/img/chabbat.png"
    title="Le Chabbat"
    content={
      <>
        <div className="font-['Great_Vibes'] tracking-[0.2em] w-full flex items-center justify-center text-center text-4xl">
          Léa & David
        </div>
        <div className="w-full text-center italic my-10">
          seraient heureux de vous retrouver pour leur chabbat Hatan
        </div>
        <div className="w-full text-center my-10 font-light text-md">
          <p>le Vendredi 23 & Samedi 24 Décembre 2022</p>
          <p>à la synagogue &apos;&apos;Michkenot Israël&apos;&apos;</p>
          <p className="italic text-sm">6 Rue Jean Nohain, 75019 Paris</p>
        </div>
      </>
    }
  />
);

export default Chabbat;
