import Chabbat from './views/Chabbat';
import Henne from './views/Henne';
import Intro from './views/Intro';
import Mairie from './views/Mairie';
import Maps from './views/Maps';
import Mariage from './views/Mariage';
import Outro from './views/Outro';
import Planning from './views/Planning';
import React from 'react';
import { useToken } from '../../../hooks';

interface InvitationProps {
  scrollTo: (_: number) => void;
}

const Invitation: React.FC<InvitationProps> = ({ scrollTo }) => {
  const token = useToken();

  return (
    <>
      <Intro scrollTo={scrollTo} />
      <Planning />
      <>
        <Mairie />
        <Maps
          title="Mairie du XIXe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.7039887954184!2d2.3797938158222687!3d48.882919279289986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66ddaa2400b67%3A0x334473c7ba88933d!2sMairie%20du%2019%C3%A8me%20arrondissement%20de%20Paris!5e0!3m2!1sfr!2sfr!4v1665274972810!5m2!1sfr!2sfr"
        />
      </>
      {token.henne && (
        <>
          <Henne />
          <Maps
            title="Espace Royal"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.3740285631484!2d2.4179608158212664!3d48.85107767928689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e672830ba2da27%3A0xf0d909bba1bc61bc!2sEspace%20Royal!5e0!3m2!1sfr!2sfr!4v1665275120086!5m2!1sfr!2sfr"
          />
        </>
      )}
      <>
        <Mariage />
        <Maps
          title="La Palmeraie"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.4658219276025!2d2.2737095158205802!3d48.8302527792848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6707633a4987b%3A0x676a85d32830312e!2sLa%20Palmeraie%20Paris!5e0!3m2!1sfr!2sfr!4v1665275161720!5m2!1sfr!2sfr"
        />
      </>
      {token.chabbat && (
        <>
          <Chabbat />
          <Maps
            title="Synagogue Michkenot Israël"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.7116271215295!2d2.371660515822284!3d48.882773679289926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66dd972276507%3A0x63ddfc6996c66433!2sSynagogue%20Michkenot%20Isra%C3%ABl!5e0!3m2!1sfr!2sfr!4v1665275412475!5m2!1sfr!2sfr"
          />
        </>
      )}
      <Outro />
    </>
  );
};

export default Invitation;
