import React, { useEffect } from 'react';

import { FaArrowCircleDown } from 'react-icons/fa';
import Logo from '../../../../components/Logo';
import { useInView } from 'react-intersection-observer';

interface IntroProps {
  scrollTo: (_: number) => void;
}

const Intro: React.FC<IntroProps> = ({ scrollTo }) => {
  const { ref, inView } = useInView();

  const [animated, setAnimated] = React.useState(false);

  useEffect(() => {
    if (inView && !animated) {
      setAnimated(true);
    }
  }, [animated, inView]);

  return (
    <div ref={ref} className="w-full h-screen flex flex-col items-center justify-center relative">
      <div className="w-full h-screen overflow-hidden bg-cover absolute top-0 left-0 z-0">
        <div
          style={{
            backgroundImage: "linear-gradient(to bottom, #0f172a88, #0f172a88), url('/img/us.png')",
          }}
          className={`w-full h-screen md:bg-fixed bg-center bg-cover absolute origin-center ${
            animated && 'animate-[kenburnsOut_10s_ease-out]'
          }`}
        />
      </div>
      <div className="absolute top-24 w-full flex items-center justify-center">
        <Logo color="#FFFFFF" scale={0.25} />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-12 z-10 text-white text-center mx-8 flex flex-row items-center justify-center gap-4 text-5xl">
          <p className="font-['Great_Vibes'] tracking-[0.1em]">Léa</p>
          <p className="font-['Great_Vibes'] tracking-[0.1em] mt-2">&amp;</p>
          <p className="font-['Great_Vibes'] tracking-[0.1em]">David</p>
        </div>
        <div className="tracking-[0.2em] text-white text-xl z-10 font-extralight">
          22 Décembre 2022
        </div>
      </div>
      <div
        onClick={() => scrollTo(document.getElementById('planning')?.offsetTop || 600)}
        className="absolute bottom-24 w-full cursor-pointer flex items-center justify-center animate-bounce"
      >
        <FaArrowCircleDown className="text-4xl fill-white" />
      </div>
    </div>
  );
};

export default Intro;
