import { Color, Solver, hexToRgb } from './utils';

import React from 'react';

interface LogoProps {
  scale?: number;
  color?: string;
}

const Logo: React.FC<LogoProps> = ({ scale = 1, color = '#000000' }) => {
  const rgb = hexToRgb(color);
  const finalColor = new Color(rgb[0], rgb[1], rgb[2]);
  const result = new Solver(finalColor).solve();

  return (
    <div className={`w-[${scale * 466}px] h-[${scale * 466}px] flex items-center justify-center`}>
      <div className={`scale-[${scale}]`}>
        <div
          className={`w-[466px] h-[466px] bg-transparent rounded-full relative border-8 border-[${color}]`}
        >
          <div
            className={`font-['Great_Vibes'] text-[${color}] text-[200px] absolute top-[69px] left-[82px] leading-none`}
          >
            L
          </div>
          <div
            className={`font-['Great_Vibes'] text-[${color}] text-[200px] absolute bottom-[39px] right-[72px] leading-none`}
          >
            D
          </div>
          <img
            src="img/diamond.png"
            alt="diamond"
            style={{ filter: result.filter }}
            className="absolute top-[178px] left-[203px] max-w-[45px] max-h-[45px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Logo;
